<template>
  <div class="container is-fluid">
    <b-button class="button-mt-1" type="is-primary" @click="fetchData()"
      >Reload Data</b-button
    >
    <b-table
      :data="resourceConfigs"
      style="margin-top: 1rem"
      striped
      hoverable
      :scrollable="true"
      paginated
      sticky-header
      searchable
      height="500px"
      :loading="isLoading"
    >
      <b-input
        v-if="!props.column.numeric"
        slot="searchable"
        v-model="props.filters[props.column.field]"
        slot-scope="props"
        icon="search"
        size="is-small"
      />

      <b-table-column v-slot="props" field="resourceId" width="100">
        <b-tooltip label="Tap to preview" position="is-right">
          <img
            v-if="thumbArray[props.row.resourceId]"
            :src="thumbArray[props.row.resourceId]"
            class="avatar"
            @click="showImageModal(props.row)"
          />
        </b-tooltip>
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="resourceId"
        label="Resource ID"
        sortable
        searchable
      >
        {{ props.row.resourceId }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="nameOverride"
        label="Name"
        sortable
        searchable
      >
        {{ props.row.nameOverride }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="datasetId"
        label="Dataset"
        sortable
        searchable
      >
        {{ props.row.datasetId }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="funFact"
        label="Fun Fact"
        sortable
        searchable
      >
        {{ props.row.funFact }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="licencePlate"
        label="Licence Plate"
        sortable
        searchable
      >
        {{ props.row.licencePlate }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="createDate"
        label="Created"
        sortable
      >
        {{ formatDate(props.row.createdDate) }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="modifiedDate"
        label="Modified"
        sortable
      >
        {{ formatDate(props.row.modifiedDate) }}
      </b-table-column>
      <b-table-column v-slot="props" field="photoName" label="Photo?" centered>
        <b-icon
          :type="props.row.photoName ? 'is-success' : 'is-danger'"
          :icon="props.row.photoName ? 'file-check' : 'file-times'"
        >
        </b-icon>
      </b-table-column>
      <b-table-column v-slot="props" custom-key="Update" sortable centered>
        <b-button type="is-success" @click="onUpdateClick(props.row)"
          >Update Info</b-button
        >
      </b-table-column>
      <b-table-column v-slot="props" custom-key="Upload" sortable centered>
        <b-button type="is-primary" @click="onUploadClick(props.row)"
          >Upload Photo</b-button
        >
      </b-table-column>
      <b-table-column v-slot="props" custom-key="Delete" sortable centered>
        <b-button type="is-danger" @click="handleDelete(props.row)"
          >Delete Photo</b-button
        >
      </b-table-column>
    </b-table>
    <b-button type="is-primary" @click="onCreateClick()"
      >Create new resource</b-button
    >
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import {
  getAllResourceConfigs,
  uploadResourcePhoto,
  updateResourceInfo,
  createResourceInfo,
  deleteResourcePhoto,
  getThumb,
  convertBinaryToImage,
} from '../../../services/resourceConfigService';
import UpdateResourceConfig from './edit/UpdateResourceConfig';
import UploadResourcePhoto from './edit/UploadResourcePhoto';
import CreateResourceConfig from './edit/CreateResourceConfig';
import ImagePreview from './ImagePreview.vue';

export default defineComponent({
  name: 'DisplayResourceConfig',
  data() {
    return {
      resourceConfigs: [],
      isLoading: false,
      thumbArray: {},
      timezone: this.$moment.tz.guess(),
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        this.isLoading = true;
        this.resourceConfigs = await getAllResourceConfigs();
        this.resourceConfigs.forEach((resource) => {
          this.getThumbnail(resource.resourceId, resource.datasetId);
        });
      } catch (err) {
        console.error(err);
        this.showToast(`Failed to retrieve data: ${err.message}`, false);
      } finally {
        this.isLoading = false;
      }
    },
    showImageModal(resource) {
      this.$buefy.modal.open({
        parent: this,
        component: ImagePreview,
        props: {
          image: this.thumbArray[resource.resourceId],
          resourceName: resource.nameOverride,
          resourceId: resource.resourceId,
        },
        hasModalCard: true,
      });
    },
    formatDate(dateStr) {
      if (!dateStr) return '';
      return this.$moment
        .tz(new Date(dateStr), this.timezone)
        .format('DD-MM-YYYY HH:mm');
    },
    onUpdateClick(resource) {
      this.$buefy.modal.open({
        parent: this,
        component: UpdateResourceConfig,
        props: {
          resource: resource,
        },
        events: {
          update: this.handleUpdate,
        },
        hasModalCard: true,
      });
    },
    onCreateClick() {
      this.$buefy.modal.open({
        parent: this,
        component: CreateResourceConfig,
        events: {
          create: this.handleCreate,
        },
        hasModalCard: true,
      });
    },
    onUploadClick(resource) {
      this.$buefy.modal.open({
        parent: this,
        component: UploadResourcePhoto,
        'full-screen': true,
        props: {
          resource: resource,
        },
        events: {
          'upload-photo': this.handleUpload,
        },
        hasModalCard: true,
      });
    },
    async handleUpdate(resource) {
      try {
        this.isLoading = true;
        await updateResourceInfo(resource);
        this.showToast(`Resource ${resource.resourceId} updated`, true);
      } catch (err) {
        this.showToast(
          `Resource ${resource.resourceId} resource update`,
          false,
        );
      } finally {
        this.isLoading = false;
      }
      await this.fetchData();
    },
    async handleCreate(resource) {
      try {
        this.isLoading = true;
        await createResourceInfo(resource);
        this.showToast(`Resource ${resource.resourceId} created`, true);
      } catch (err) {
        this.showToast(
          `Resource ${resource.resourceId} resource create`,
          false,
        );
      } finally {
        this.isLoading = false;
      }
      await this.fetchData();
    },
    async handleDelete(resource) {
      try {
        this.isLoading = true;
        await deleteResourcePhoto(resource);
        this.showToast(
          `Photo for resource ${resource.resourceId} deleted`,
          true,
        );
      } catch (err) {
        this.showToast(
          `Resource ${resource.resourceId} photo delete failed`,
          false,
        );
      } finally {
        this.isLoading = false;
      }
      await this.fetchData();
      delete this.thumbArray[resource.resourceId];
    },
    async handleUpload(resource) {
      try {
        this.isLoading = true;
        await uploadResourcePhoto({
          resourceId: resource.resourceId,
          photo: resource.file,
          datasetId: resource.datasetId,
        });
        this.showToast(`Resource ${resource.resourceId} photo uploaded`, true);
      } catch (err) {
        this.showToast(
          `Resource ${resource.resourceId} photo upload failed failed`,
          false,
        );
      } finally {
        this.isLoading = false;
      }
      await this.fetchData();
    },
    async getThumbnail(resourceId, datasetId) {
      const rawThumb = await getThumb(resourceId, datasetId);
      const img = await convertBinaryToImage(rawThumb);
      this.$set(this.thumbArray, resourceId, img);
    },
    showToast(msg, successful) {
      this.$buefy.toast.open({
        message: msg,
        type: successful ? 'is-success' : 'is-danger',
        duration: 5000,
      });
    },
  },
});
</script>

<style>
.button-mt-1 {
  margin-top: 1em;
}

.avatar {
  display: inline-block;
  color: white;
  width: 80px;
  height: 80px;
  font-size: 12px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: none;
  border-width: 0;
}
</style>
