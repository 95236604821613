import axios from 'axios';
import FileType from 'file-type/browser';

import configData from '../config/config.json';
import { getIdToken } from '@/services/auth/index';

const apiClient = axios.create({
  baseURL: configData.omwResourceConfig.baseUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(
  (config) => {
    const token = getIdToken();
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
      return config;
    }
    throw new Error('No access token Found');
  },
  (err) => Promise.reject(err),
);

export const getAllResourceConfigs = async () => {
  const options = {
    method: 'GET',
    url: '/',
  };
  const { data } = await apiClient(options);
  return data;
};

export const updateResourceInfo = async ({
  resourceId,
  funFact,
  nameOverride,
  licencePlate,
  datasetId,
}) => {
  const params = new URLSearchParams();
  const options = {
    method: 'PATCH',
    url: `/${resourceId}`,
    data: {
      resourceId,
      funFact,
      nameOverride,
      licencePlate,
      datasetId,
    },
    params,
  };
  const { data } = await apiClient(options);
  return data;
};

export const createResourceInfo = async ({
  resourceId,
  funFact,
  name,
  licencePlate,
  datasetId,
}) => {
  const params = new URLSearchParams();
  params.append('datasetId', datasetId);
  const options = {
    method: 'POST',
    url: `/`,
    data: {
      resourceId,
      datasetId,
      funFact,
      name,
      licencePlate,
    },
    params,
  };
  const { data } = await apiClient(options);
  return data;
};

export const deleteResourcePhoto = async ({
  resourceId,
  funFact,
  nameOverride,
  datasetId,
}) => {
  const params = new URLSearchParams();
  params.append('datasetId', datasetId);

  const options = {
    method: 'DELETE',
    url: `/${resourceId}/photo`,
    data: {
      resourceId,
      funFact,
      nameOverride,
    },
    params,
  };
  const { data } = await apiClient(options);
  return data;
};

export const uploadResourcePhoto = async ({ resourceId, photo, datasetId }) => {
  const params = new URLSearchParams();
  params.append('datasetId', datasetId);

  const options = {
    method: 'POST',
    url: `/${resourceId}/photo`,
    headers: {
      'content-type': 'image/jpeg',
    },
    data: photo,
    params,
  };
  return apiClient(options);
};

export const getResourcePhoto = async (resourceId, datasetId) => {
  const params = new URLSearchParams();
  params.append('datasetId', datasetId);

  const options = {
    method: 'GET',
    url: `/${resourceId}/photo`,
    responseType: 'arraybuffer',
    headers: {
      accept: 'image/jpg, image/png',
    },
    params,
  };
  const { data } = await apiClient(options);
  return data;
};

export const getThumb = async (resourceId, datasetId) => {
  const params = new URLSearchParams();
  params.append('datasetId', datasetId);

  const options = {
    method: 'GET',
    url: `/${resourceId}/thumb`,
    responseType: 'arraybuffer',
    headers: {
      accept: 'image/jpg, image/png',
    },
    params,
  };
  const { data } = await apiClient(options);
  return data;
};

export function convertBinaryToImage(rawPhoto) {
  return new Promise((resolve) => {
    if (rawPhoto && rawPhoto.byteLength > 0) {
      FileType.fromBuffer(rawPhoto).then((foundType) => {
        const blob = new Blob([rawPhoto], { type: foundType.mime });
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => resolve(reader.result);
      });
    }
  });
}
