<template>
  <validation-observer v-slot="{ invalid }">
    <div class="modal-card" style="width: 700px">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ titleText }}</p>
      </header>
      <section class="modal-card-body">
        <b-field horizontal label="Resource ID">
          <b-input v-model="localResource.resourceId" disabled type="text">{{
            localResource.resourceId
          }}</b-input>
        </b-field>
        <b-field horizontal label="Dataset">
          <b-input v-model="localResource.datasetId" disabled type="text">{{
            localResource.datasetId
          }}</b-input>
        </b-field>

        <validation-provider v-slot="{ errors }" rules="required" name="Name">
          <b-field horizontal label="Name">
            <b-input v-model="localResource.nameOverride">{{
              localResource.nameOverride
            }}</b-input>
          </b-field>
          <span class="help has-text-danger">
            {{ errors[0] }}
          </span>
        </validation-provider>

        <b-field horizontal label="Fun Fact">
          <b-input v-model="localResource.funFact">{{
            localResource.funFact
          }}</b-input>
        </b-field>

        <b-field horizontal label="Licence Plate">
          <b-input v-model="localResource.licencePlate">{{
            localResource.licencePlate
          }}</b-input>
        </b-field>

        <b-field horizontal label="Created On">
          <b-input v-model="createdDate" disabled> </b-input>
        </b-field>

        <b-field horizontal label="Last Modified">
          <b-input v-model="modifiedDate" disabled></b-input>
        </b-field>
      </section>

      <footer class="modal-card-foot">
        <b-button type="is-primary" @click="$parent.close()"> Close </b-button>
        <b-button :disabled="invalid" type="is-primary" @click="save">
          Save
        </b-button>
      </footer>
    </div>
  </validation-observer>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
} from 'vee-validate';

setInteractionMode('aggressive');

export default defineComponent({
  name: 'UpdateResourceInfo',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    resource: {
      type: Object,
      required: false,
      default: () => {
        return {
          resourceId: '',
          datasetId: '',
          createdDate: undefined,
          modifiedDate: undefined,
          nameOverride: '',
          funFact: undefined,
          licencePlate: '',
        };
      },
      // TODO: add validator
    },
  },
  data() {
    return {
      localResource: {
        resourceId: '',
        datasetId: '',
        createdDate: undefined,
        modifiedDate: undefined,
        nameOverride: '',
        funFact: undefined,
        licencePlate: '',
      },
    };
  },
  computed: {
    titleText() {
      return 'Update Resource Config';
    },
    createdDate() {
      if (!this.localResource || !this.localResource.createdDate) return '';
      const createdDate = new Date(this.localResource.createdDate);
      const tz = this.$moment.tz.guess();
      return this.$moment.tz(createdDate, tz).format('DD-MM-YYYY HH:mm:ss');
    },
    modifiedDate() {
      if (!this.localResource || !this.localResource.modifiedDate) return '';
      const modifiedDate = new Date(this.localResource.modifiedDate);
      const tz = this.$moment.tz.guess();
      return this.$moment.tz(modifiedDate, tz).format('DD-MM-YYYY HH:mm:ss');
    },
  },
  created() {
    this.localResource = this.$copy(this.resource);
  },
  methods: {
    save() {
      this.$emit('update', this.localResource);
      this.$parent.close();
    },
  },
});
</script>
